import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Document = ({ pageContext }) => (
  <div>
    <h2>{pageContext.title}</h2>
      <p>{pageContext.description}</p>
      {pageContext.body ? documentToReactComponents(pageContext.body.json) : null}
  </div>
);

export default Document;
